@import "theme.scss";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import '~fearless-common-ui/src/styles/base';
@import "animate-by-daneden.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
h1 {
  font-size: 40px;
  font-weight: 700;
}
h2 {
  font-size: 35px;
  font-weight: 700;
}
h3 {
  font-size: 20px;
  font-weight: 700;
}
h4 {
  font-size: 20px;
  font-weight: 600;
}

p, li {
  color: #454545;
  line-height: 1.75;
  b, strong {
    font-weight: 700;
  }
}
.cursor-pointer { cursor: pointer; }

.line-height-1-5 { line-height: 1.5; }
.line-height-1-2 { line-height: 1.2; }
.line-height-1-0 { line-height: 1.0; }
.line-height-2 { line-height: 2; }

.bg-white { background-color: #FFF !important; }
.bg-secondary-dark { background-color: $secondaryDarker; }
.linear-gradient-film {
  background: linear-gradient(to right, $darkest 0%, $darkest 100%) ;
  background-repeat: no-repeat;
  background-size: cover;
}
.border-gray {
  border-color: #aaa;
}
.active-color { color: $secondaryDarker; }
.border-neutral-200 { border-color: $neutral-200 !important; }
.border-neutral-300 { border-color: $neutral-300 !important; }
.border-neutral-400 { border-color: $neutral-400 !important; }
.border-neutral-500 { border-color: $neutral-500 !important; }
.border-neutral-600 { border-color: $neutral-600 !important; }
.border-neutral-700 { border-color: $neutral-700 !important; }
.border-neutral-800 { border-color: $neutral-800 !important; }

.gradient-color {
  background: -webkit-linear-gradient($dark, $darker);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-color-gray {
  background: -webkit-linear-gradient($gray-400, $gray-700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}
.gradient-color-secondary {
  background: -webkit-linear-gradient($secondaryLighter, $secondaryDarker);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-dark p {
  color: $fontColorLight;
}
.bg-dark { background-color: $dark; }
.bg-darker { background-color: $darker !important; }
.bg-darkest { background-color: $darkest; }
.btn-link {
  color: $actionColor;
  &:hover {
    color: $actionColorLight !important;
  }
}
.btn.btn-action {
  background-color: $actionColor !important;
  border-color: $actionColor !important;
  color: #fff;
  &:hover {
    background-color: $actionColorLight !important;
    border-color: $actionColorLight !important;
    color: #fff;
  }
}
.public {
  .btn-primary {
    background-color: $actionColor;
    border-color: $actionColor;
    &:hover {
      background-color: $actionColorLight !important;
      border-color: $actionColorLight !important;
    }
  }
  .btn-secondary {
    background-color: $darkest;
    border-color: $darkest;
    &:hover {
      background-color: $darker !important;
      border-color: $darker !important;
    }
  }
}
.btn-xl {
  padding: 10px 25px;
  font-size: 1.3rem;
}
.bg-light { background-color: $light !important; }
.bg-lighter { background-color: $lighter !important; }
.bg-lightest { background-color: $lightest !important; }

.bg-neutral-200 { background-color: $neutral-200; }

.bg-blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  z-index: -1;
}
.accordion {
  --bs-accordion-active-color: $white;
  --bs-accordion-active-bg: $darkest;
}
.accordion-button {
  font-weight: 600;
  font-family: "Poppins", sans-serif;

  &.collapsed {
    background-color: $neutral-100;
    border-bottom: 0 solid $lighter;
    &:hover {
      background-color: $lightest;
    }
  }
  &:not(.collapsed) {
    background-color: $neutral-300;
  }
}
.boxed.nav {
  gap: 0.25rem;

  +.tab-content {
    background-color: $lightest;
    padding: 2rem 3rem;
  }
  .nav-link {
    background-color: $lighter;
    border: none;
    border-radius: 0;
    padding: 1rem 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: $font-size-lg;
    color: $black;
    &.active {
      background-color: $lightest;
    }
  }
  .nav-item:last-of-type {
    .nav-link {
      border-top-right-radius: $roundness-lg;
    }
  }
}
.nav-tabs {
  > .nav-item {
    background-color: $neutral-100;
    margin-right: 5px;
    button.nav-link {
      color: $neutral-500;
    }
    button.active {
      color: $neutral-800;
    }
  }
}
.text-white {
  color: #fff !important;
  p {
    color: #fff !important;
  }
}
.text-darkest {
  color: $darkest;
}
.text-primary {
  color: $actionColor;
}
.gray-100 { color: $gray-100 !important; }
.gray-200 { color: $gray-200 !important; }
.gray-300 { color: $gray-300 !important; }
.gray-400 { color: $gray-400 !important; }
.gray-500 { color: $gray-500 !important; }
.gray-600 { color: $gray-600 !important; }
.gray-700 { color: $gray-700 !important; }
.gray-800 { color: $gray-800 !important; }


.text-primary-dark {
  color: $darker;
}

.bg-complementary { background-color: #017A61; }
.bg-alternative { background-color: #C00256; }
.container-xs {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.container-sm {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

label.form-check-label {
  font-weight: $form-label-font-weight;
  color: $gray-700;
}
.form-floating {
  > label {
    color: $gray-600;
    padding: 1rem 1.5rem;
  }
  > input.form-control, > select.form-control {
    padding: 1.25rem 1.5rem 0.5rem 1.5rem !important;
  }
}
label.disabled {
  color: $gray-400;
}
.form-control, .input-group-text {
  border: 1px solid rgba(237, 242, 244, 0.5);
  background-color: rgba(237, 242, 244, 0.5) !important; // RGBA version of #edf2f4
  border-radius: 25px;
}
input[type='radio'] {
  border: $input-border-width solid $input-border-color;
  margin-right: 1rem;
}

*[disabled] {
  filter: grayscale(100%);
}

.text-sm { font-size: $font-size-base * 0.8; }
.text-lg { font-size: $font-size-base * 1.2; }
.text-xl { font-size: $font-size-base * 1.5; }
.text-xxl { font-size: $font-size-base * 1.8; }
.text-xxxl { font-size: $font-size-base * 2; }

@media only screen and (max-width: $boundaryMid) {
  .text-xxxl { font-size: $font-size-base * 1.5; }
  .text-xxl { font-size: $font-size-base * 1.5; }
}

.text-right { text-align: right; }
.text-left { text-align: left; }
.required-field::after {
  content: ' *';
  display: inline;
  color: $danger;
  z-index: 10;
  position: relative;
}

// Layout styling
.LoginDropdown {
  color: #fff;
  line-height: 1;

  .dropdown-toggle {
    padding: 0;
  }
  .dropdown-menu {
    width: 100%;
    border: none;
    box-shadow:0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)
  }
  a.dropdown-item {
    font-size: 1rem;
  }
  a.dropdown-toggle {
    display: flex;
    align-items: center;
  }
}
.mt-vh5 { margin-top: 5vh; }
.mt-vh10 { margin-top: 10vh; }
.mt-vh15 { margin-top: 15vh; }
.mt-vh20 { margin-top: 20vh; }
.mt-vh25 { margin-top: 25vh; }

.mb-vh5 { margin-bottom: 5vh; }
.mb-vh10 { margin-bottom: 10vh; }
.mb-vh15 { margin-bottom: 15vh; }
.mb-vh20 { margin-bottom: 20vh; }
.mb-vh25 { margin-bottom: 25vh; }

.pt-vh5 { padding-top: 5vh; }
.pt-vh10 { padding-top: 10vh; }
.pt-vh15 { padding-top: 15vh; }
.pt-vh20 { padding-top: 20vh; }
.pt-vh25 { padding-top: 25vh; }

.max-width-800 { max-width: 800px; }
.max-width-700 { max-width: 700px; }
.max-width-600 { max-width: 600px; }
.max-width-500 { max-width: 500px; }
.max-width-400 { max-width: 400px; }
.max-width-300 { max-width: 300px; }
.max-width-200 { max-width: 200px; }
.rounded-bottom-lg {
  border-bottom-left-radius: $roundness-lg;
  border-bottom-right-radius: $roundness-lg;
}
.rounded-top-md {
  border-top-left-radius: $roundness-md !important;
  border-top-right-radius: $roundness-md !important;
}
.rounded-lg {
  border-radius: $roundness-lg;
}
.rounded-bottom-right-lg {
  border-bottom-right-radius: $roundness-lg;
}
.rounded-top-right-lg {
  border-top-right-radius: $roundness-lg;
}
.py-vh5 {
  padding-top: 5vh;
  padding-bottom: 5vh;
}
.py-vh7 {
  padding-top: 7vh;
  padding-bottom: 7vh;
}
.py-vh10 {
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.py-vh15 {
  padding-top: min(15vh, 180px);
  padding-bottom: min(15vh, 180px);
}
.py-vh20 {
  padding-top: min(20vh, 250px);
  padding-bottom: min(20vh, 250px);
}
.py-vh30 {
  padding-top: min(30vh, 360px);
  padding-bottom: min(30vh, 360px);
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.text-xs {
  font-size: 0.7rem;
}

/**
  Hover utility classes which can be used anywhere to show items on hover
 */
.hover-relative {
  .hover-item {display: none}
  &:hover .hover-item { display: block; }
}
.bg-dark-transparent {
  background-color: $darkest;
  position: relative;

  &.bg-dark-transparent-span-before::before {
    content: '';
    height: 100%;
    background-color: $darkest;
    width: 100vw;
    position: absolute;
    right: 100%;
    top: 0;
  }
  &.bg-dark-transparent-span-after::after {
    content: '';
    height: 100%;
    background-color: $darkest;
    width: 100vw;
    position: absolute;
    left: 100%;
    top: 0;
  }
}

// TODO must not be sticky on mobile
.sticky-top-offset {
  padding-top: 80px;
}

.font-header { font-family: "Roboto", sans-serif; }

.modal-backdrop, .modal {
  // Make sure the modal backdrop has the same z-index as the modal, so that
  // when you have multiple modals open, the top modal's backdrop is covering
  // the bottom modal's content.
  --bs-modal-zindex: 1055;
  --bs-backdrop-zindex: 1055;
  --bs-backdrop-bg: rgba(255, 255, 255, 0.5);
  --bs-backdrop-opacity: 1;
  z-index: 1055 !important;
}
.modal-backdrop.show {
  filter: blur(2px);
  backdrop-filter: blur(2px);
}

.hover-bg-dark:hover {
  background-color: $darker;
  color: #fff;
}

