@import "/src/variables";

.LoginLanding {
    background-position: center top;
    background-size: 150%;
}
@media only screen and (max-width: $boundaryMin) {
    .LoginLanding {
        background-position: -60vh top;
        background-size: cover;
    }
}
