@import "/src/variables";

.TableContainer {
    position: relative;
    display: block;
    max-height: max(400px, 80vh);
    overflow-y: scroll;
}
.Table {
    > thead {
        width: 100%;
        > tr > th {
            color: $actionColor;
            position: sticky;
            top: 0;
            background-color: #fff;
        }
    }
}
