@import "src/variables";
$size: 24px;
$borderSize: 4px;

.CircularTimer {
    width: $size;
    height: $size;
    margin: 0 auto;
    position: relative;
}
.outerShadow,
.innerShadow {
    z-index: 4;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}
$innerWidth: $size - 2 * $borderSize;
.innerShadow {
    top: 50%;
    left: 50%;
    width: $innerWidth;
    height: $innerWidth;
    margin-left: -0.5 * $innerWidth;
    margin-top: -0.5 * $innerWidth;
    border-radius: 100%;
    background-color: transparent;
}
.hold {
    position: absolute;
    width: 100%;
    height: 100%;
    clip: rect(0px, $size, $size, $size/2);
    border-radius: 100%;
    background-color: transparent;
}

.fill {
    background-color: $gray-500;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    clip: rect(0px, $size/2, $size, 0px);
    animation-timing-function: linear;
    animation-fill-mode: both;
}
.left .fill {
    z-index: 1;
    animation-name: left;
}
@keyframes left {
    0% { -webkit-transform: rotate(0deg); }
    100% { transform: rotate(180deg); }
}
@-webkit-keyframes left {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(180deg); }
}
.right {
    z-index: 3;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}
.right .fill {
    z-index: 3;
    animation-name: right;
}
@keyframes right {
    0% { -webkit-transform: rotate(0deg); }
    100% { transform: rotate(180deg); }
}
@-webkit-keyframes right {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(180deg); }
}
