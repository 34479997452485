@import "/src/variables";

.Rounded {
    border-top-left-radius: $roundness-lg;
    border-bottom-left-radius: $roundness-lg;
}
.Icon {
    height: 150px;
    margin-left: 50px;
}
