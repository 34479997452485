@import "/src/variables";

.Table {
    -ms-grid-columns: (minmax(270px, 270px)) [auto-fit];
    grid-gap: 5px;

    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(275px, 275px));
    width: 100%;
    &.Center {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
    }
    &.Left {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
    }

    .ProductName {
        flex: 0 0 40px;
        font-weight: 600;
        font-size: 20px;
        text-align: left;
    }
    .ProductDescription {
        font-size: 14px;
        opacity: 0.5;
        color: #1a1a1a;
        margin-bottom: 36px;
        text-align: left;
    }

    .PriceColumn {
        padding: 34px 23px 28px;
        width: 270px;
        background-color: $lightest;
        border: 5px solid $lightest;
        border-top-right-radius: $roundness-lg;
        transition: all .2s ease-out;
        &.BestValue {
            border: 2px solid $primary;
        }
        &:hover {
            transform: scale(1.1);
            transform-origin: center center;
            box-shadow: $box-shadow-lg;
            border: 2px solid $white;
            z-index: 2;
        }
    }
    .PriceContainer {
        height: 80px;
        text-align: center;
    }
}
.BestValueSpan {
    position: absolute;
    top: -12px;
    width: 100px;
    text-align: center;
    font-weight: bold;
    display: block;
    padding: 3px ;
    left: calc(50% - 50px);
    font-size: $font-size-base * 0.8;
    background-color: $primary;
    color: $white;
    margin: auto;
    border-radius: 20px;
}
.Price {
    line-height: 1;
    font-size: 40px;
    font-weight: 900;
    margin-right: 4px;
    height: 45px !important;
    letter-spacing: -2px;
    display: inline-flex;
    align-items: center;

    &.Small {
        height: 25px !important;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: -0px;
    }
}
.PriceUnit {
    display: inline-block;
    text-align: left;
    min-width: 61px;
    opacity: 0.5;
    color: #1a1a1a;
    font-size: 13px;
    margin: 0;
    line-height: 1.15;
}

.Button {
    padding: 12px 24px;
    margin-top: 16px;
    margin-bottom: 32px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
}
