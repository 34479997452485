.GroupSelectorWrapper {
    > div {
        border-radius: 80px;
        background-color: #fff !important;
        border: 1px solid #ddd;
    }
    .form-control {
        background-color: #ffffff !important;
    }
}

