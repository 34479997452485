@font-face {
    font-family: "Marion";
    src: local('marion.ttf'), url("./assets/fonts/marion.ttf");
    font-display: swap; // This will swap font once its loaded, for SEO purpose.
}
@font-face {
    font-family: "Copperplate";
    src: local('Copperplate-Regular.ttf'), url("./assets/fonts/Copperplate-Regular.ttf");
    font-display: swap; // This will swap font once its loaded, for SEO purpose.
}
@font-face {
    font-family: "Avenir";
    src: local('AvenirLTStd-Book.otf'), url("./assets/fonts/AvenirLTStd-Book.otf");
    font-display: swap; // This will swap font once its loaded, for SEO purpose.
}
@font-face {
    font-family: "Roboto";
    src: url("./assets/fonts/roboto/Roboto-Regular.ttf");
    font-display: swap; // This will swap font once its loaded, for SEO purpose.
}
@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/poppins/Poppins-Regular.ttf");
    font-display: swap; // This will swap font once its loaded, for SEO purpose.
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/poppins/Poppins-SemiBold.ttf");
    font-display: swap; // This will swap font once its loaded, for SEO purpose.
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/poppins/Poppins-Bold.ttf");
    font-display: swap; // This will swap font once its loaded, for SEO purpose.
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/poppins/Poppins-ExtraBold.ttf");
    font-display: swap; // This will swap font once its loaded, for SEO purpose.
    font-weight: 700;
    font-style: normal;
}

/**
This file should contain variables only.
 */
$dark: #8d99ae;
$darker: #5d6d84;
$darkest: #2b2d42;
$light: #669bbc;
$lighter: #cad9dd;
$lightest: #edf2f4;
$actionColor: #a51c30;
$actionColorLight: #cb0400;

$primary: #2b2d42;
$secondaryLightest: #B6EAFE;
$secondaryLighter: #B3DEE5;
$secondary: #017BAD;
$secondaryDarker: #01577A;

$accent2: #017A61;

// FONT COLORS

$fontColorLight: #ccc;
$fontColorLighter: #707070;
$fontColor: #454545;
$fontColorDarker: #222;
$link-color: $accent2;

$font-size-base: 1rem;
$line-height-base: 1.5;

$h1-font-size: 2.1rem;
$headings-font-weight: 600;
$navbar-nav-link-padding-x: 1.5rem;
$navbar-nav-link-padding-y: 2rem;

$nav-link-font-size: 1rem;
$nav-link-font-weight: 500;
$nav-link-color: $actionColor;

$form-label-font-weight: 600;
$form-label-color: $fontColorDarker;

// FORMS

$input-group-addon-bg: transparent;
$form-floating-input-padding-b: 0.325rem;
$form-floating-input-padding-t: 1.325rem;
$form-floating-label-transform: scale(0.85) translateY(-0.7rem) translateX(0.15rem);

// BUTTON

$btn-border-radius: 50px;
$btn-border-radius-lg: 50px;
$btn-font-size-lg: $font-size-base;

// DROPDOWN

$dropdown-link-hover-bg: #5D6D84;
$dropdown-link-hover-color: #FFF !important;

// ACCORDION

$accordion-active-bg: $darkest;
$accordion-inner-border-radius: '0px';

$navigationHeight: 83px;
$roundness-lg: 50px;
$roundness-md: 25px;

$boundaryMax: 1580px;
$boundaryMid: 900px;
$boundaryMin: 700px;

@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
