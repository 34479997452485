
.Fold {
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: bottom;

    &.FullHeight {
        // Full view height minus the top navigation bar
        height: calc(100vh - 70px);
    }
    &.OverlayDark {
        position: relative;
        > * {
            position: relative;
            z-index: 1;
        }
        &::before {
            z-index: 0;
            position: absolute;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            top: 0;
        }
    }

    .FoldMain {
        color: #707070;
        height: 94vh;
        min-height: 500px;
        max-height: 900px;
        display: flex;
        flex-direction: column;
        padding-top: min(15vh, 180px);
        align-items: center;
        background-size: 100% 100%;
        background-position: bottom;
    }
}
