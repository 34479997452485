@import "/src/variables";

$width: 420px;
$mobileWidth: 90vw;

.EmpowerSection {
    border-top-right-radius: $roundness-lg;
    border-bottom-right-radius: $roundness-lg;
}

.ThemedHomeFoldContent {
    background-position: center bottom !important;
    background-size: auto 17vh !important;
    background-repeat: no-repeat;
    padding-top: 8vh !important;
    overflow: visible !important;
}
.Photo51Logo {
    max-width: 60vw;
    height: 120px;
}
.RoundedBottom {
    border-bottom-left-radius: $roundness-lg;
    border-bottom-right-radius: $roundness-lg;
}
.FoldText {
    font-family: "Poppins", sans-serif;
    font-size: 3rem;
    font-weight: 700;
}
.BrownIcon {
    height: 100px;
    border-radius: 50%;
}
@media only screen and (max-height: 600px) {
    .ThemedHomeFoldContent {
        background-position: center 120% !important;
        background-size: auto 25vh !important;
    }
}
.MaxWidthParagraph {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: $boundaryMax) {
    .FoldImageAlignment {
        background-position: center 35%;
        background-size: cover !important;
    }
}

@media only screen and (min-width: $boundaryMid) and (max-width: $boundaryMax) {
    .FoldImageAlignment {
        background-position: center 35%;
        background-size: cover;
    }
}

@media only screen and (max-width: $boundaryMid) {
    .FoldImageAlignment {
        background-position: 60% top;
        background-size: cover;
    }
    .ThemedHomeFoldContent {
        padding-top: 80px !important;
    }
    .IconBlock {
        position: relative;
        display: flex;
        align-items: center;

        .BrownIcon {
            height: 100px;
            margin-right: 15px;
            margin-bottom: 15px;
        }
    }
}
