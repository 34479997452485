@import "/src/variables";

$height: 520px;
$tabletHeight: 380px;
$mobileHeight: 250px;

.BorderTransparant {
    border-color: transparent !important;
}

.PreviewImage {
    img { max-width: 100%; }
}
.ActiveItem {
    background-color: $darkest;
    color: $white;
    border-top-right-radius: $roundness-lg;
    border-bottom-right-radius: $roundness-lg;
    p { color: $white; }
}
.Arrow {
    top: -6px;
}
.ShortenArrow {
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    background-color: $white;
}

.Dot {
    display: inline-block;
    height: 10px;
    width: 10px;
    background-color: $gray-600;
}

.PreviewContainer {
    height: $height;
    min-height: $height;
    max-height: $height;
    padding-top: $height*0.060;
    padding-left: $height*0.20;
    padding-right: $height*0.20;

    overflow: visible;
    background-image: url("./../../assets/images/backgrounds/silver_computer_in_front_view_mockup.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
}

.Shift {
    position: relative;
}
.MobileNavigation {
    height: 76px;
}

@media only screen and (min-width: $boundaryMid) and (max-width: 1390px) {
    .MobileNavigation { height: auto; }
    .PreviewContainer {
        padding-left: 4%;
        padding-right: 4%;
    }
}

@media only screen and (min-width: $boundaryMin) and (max-width: $boundaryMid) {
    .PreviewContainer {
        height: $tabletHeight;
        min-height: $tabletHeight;
        max-height: $tabletHeight;
        padding-left: 3.3%;
        padding-right: 3.3%;
    }
}

@media only screen and (max-width: $boundaryMin) {
    .PreviewContainer {
        height: $mobileHeight;
        min-height: $mobileHeight;
        max-height: $mobileHeight;
        padding-left: 3.3%;
        padding-right: 3.3%;
    }
}
