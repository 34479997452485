@import "/src/variables";

.ToggleViewingPassword {
    cursor: pointer;
    &:hover {
        color: $link-hover-color;
    }
}
.isFocused, .hasValue {
    > label::after {
        background-color: transparent !important;
    }
}
.disappearingLabel {
    > input.input,
    > textarea.input {
        padding: 1.25rem 1.5rem 1.25rem 1.5rem !important;
    }
    &.isFocused, &.hasValue {
        .floatedLabel, label {
            display: none;
        }
    }
}
