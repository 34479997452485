@import "/src/variables";

$filledBackgroundColor: #fff;
$paddingY: 7px;

.Navbar {
    transition: background-color 500ms;
    padding-top: $paddingY;
    padding-bottom: $paddingY;
    height: $navigationHeight;

    &.Regular {
        background: $filledBackgroundColor;
        box-shadow: none;
        backdrop-filter: blur(4px);
        border-bottom: none;
    }

    .MainNavLink {
        color: $gray-700;
        border-bottom: 4px solid transparent;
        border-top: 4px solid transparent;
        height: 100%;
        line-height: 1.3;
        &:hover {
            color: #000;
            border-bottom: 4px solid $actionColor;
        }
    }
}
.Main {
    margin-top: $navigationHeight;
}
@media only screen and (max-width: $boundaryMin) {
    // The height of the navbar on mobile is smaller than on desktop.
    .Main { margin-top: 74px; }
}
.Footer {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li a {
            display: block;
            padding: 5px 0;
            text-decoration: none;
            color: $gray-600;
            font-size: 1rem;
            font-weight: 600;
        }
    }
}

.UserImage {
    background-color: rgba(200,200,200, 0.8) !important;
    border: 1px solid #fff;
    height: 40px;
    width: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    overflow: hidden;
    margin-right: 10px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .Initials {
        color: rgba(0, 0, 0, 0.55);
        font-weight: bold;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        justify-content: center;
        font-size: 24px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
    }
}
.Shade {
    background-color: rgba(0,0,0,0.1);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    animation-duration: 300ms;
    z-index: 0;
}
.MobileMenu {
    max-height: 80vh;
    overflow-y: auto;
    overscroll-behavior: contain;
}

@media only screen and (max-width: $boundaryMid) {
    .Navbar {
        background: $secondary !important;
        text-align: center;

        :global a.nav-link:not(.dropdown\-item) {
            color: #fff !important;
        }
        &.Expanded {
            z-index: 2;
            box-shadow: 0 0 200px rgba(0,0,0,0.5);
            align-items: start;
        }
    }
    .MobileMenu {
        background-color: $primary !important;
        padding-bottom: 100px;
    }
    .Logo {
        filter: invert(100%);
        -webkit-filter: invert(100%);
    }
    .NavbarTogglerIcon {
        border-color: transparent !important;
        //background-color: #fff;
        font-size: 2em;
        color: #fff;
    }
}
