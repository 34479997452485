@import "/src/variables";

.Page {
    min-height: 75vh;
}
.PageBase {
    min-height: 95vh;
    .HeaderBreadcrumb {
        color: #fff;
        ol {
            margin-bottom: 0;
            li::before {
                color: #fff;
            }
        }
        .NoHref a {
            text-decoration: none;
        }
        .HeaderBreadcrumbItem a {
            color: #fff;
        }
    }
}
.Row {
    gap: $spacer * 2;
}
